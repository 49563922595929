import { useStrapiPageContent } from 'src/content/useStrapiPageContent';
import { findFirstContentWithButton } from '@types';
import { useButtonContent } from 'src/types/Button';

export const useFinnoscoreBannerContent = (id: string) => {
  const pageContent = useStrapiPageContent(id);

  const buttonContent = findFirstContentWithButton(pageContent?.content);
  const { text, link, target } = useButtonContent(buttonContent);

  return {
    title: pageContent?.title,
    paragraph: pageContent?.description,
    link,
    target,
    buttonText: text,
  };
};
