import { SxStyleProp } from 'theme-ui';
import { Theme } from '@themed';

export const styles = {
  section: {
    position: 'relative',
    width: '100%',
    height: '100%',
  } as SxStyleProp,

  sectionContent: {
    variant: 'grids.CompactSectionContentPadding',
    pb: [8, 8, 8, 5],
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'space-around',
    alignItems: 'flex-start',

    h2: {
      fontSize: ['l', 'l', 'xl'],
    },
  },

  offers: {
    flexDirection: ['column', 'column', 'row'],

    '&>*:not(:last-child)::after': {
      content: ['none', 'none', '""'],
      borderLeft: (theme: Theme) => `2px solid ${theme.colors?.background}`,
      height: '90%',
    },

    paddingLeft: [0, 0, 0, '5rem'],

    '& > div:nth-child(2)': {
      paddingLeft: [0, 0, 0, '5rem'],
      marginTop: [5, 5, 0],
    },
  },

  divider: {
    display: ['none', 'none', 'inline-block'],
    borderLeft: (theme: Theme) => `2px solid ${theme.colors?.background}`,
    height: '90%',
  },

  text: {
    my: [5, 5, 7],
    fontWeight: '500',
    fontSize: ['xs', 'xs', 's'],
    lineHeight: ['auto', 'auto', '32px'],
  },
};
