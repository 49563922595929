import React from 'react';
import gsap from 'gsap';

export type MatchMediaConditions = {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
}

type AnimationProps = {
  tl: gsap.core.Timeline;
  packageRef: () => React.RefObject<HTMLDivElement>;
  isFlipped: boolean;
};

export const animatePackage = ({ tl, packageRef, isFlipped }: AnimationProps) => {
  const packageElement = packageRef().current;
  if (!packageElement) return;

  // Initialize the card setup only once
  if (!packageElement.dataset.initialized) {
    gsap.set(packageElement, {
      transformStyle: 'preserve-3d',
      transformPerspective: 1000,
      transformOrigin: '50% 50%',
    });

    const q = gsap.utils.selector(packageElement);
    const front = q('.package-front')[0];
    const back = q('.package-back')[0];

    gsap.set(back, { rotationY: -180 });

    tl.to(packageElement, {
      z: 50,
      duration: 0.2,
    })
      .to(front, {
        rotationY: 180,
        duration: 0.6,
      }, 0)
      .to(back, {
        rotationY: 0,
        duration: 0.6,
      }, 0)
      .to(packageElement, {
        z: 0,
        duration: 0.2,
      });

    packageElement.dataset.initialized = 'true';
  }

  if (isFlipped) {
    tl.play();
  } else {
    tl.reverse();
  }
};
export const useAnimatePackage = (packageRef: React.RefObject<HTMLDivElement>, isFlipped: boolean) => {
  const timeline = React.useRef<gsap.core.Timeline>();

  React.useEffect(() => {
    // Create timeline only once
    if (!timeline.current) {
      timeline.current = gsap.timeline({
        paused: true,
        reversed: !isFlipped,
      });
    }

    if (packageRef.current) {
      animatePackage({
        tl: timeline.current,
        packageRef: () => packageRef,
        isFlipped,
      });
    }

    return () => {
      if (timeline.current) {
        timeline.current.kill();
      }
    };
  }, [isFlipped, packageRef]);
};
