/** @jsx jsx */
import { jsx, SxProps, Text } from 'theme-ui';
import React from 'react';
import { Flex, SvgIcon, SubTitle, Paragraph, CTAWithOptionalLink } from '@core';
import { __ } from '@modules';
import { styles } from './WorkshopOffer.styles';

interface FinnoscoreWorkshopOffersProps extends SxProps {
  title: string;
  id: number;
  icon: string;
}

const WorkshopOffer = React.forwardRef<HTMLDivElement, FinnoscoreWorkshopOffersProps>(({
  title, id, icon, sx,
}, ref) => {
  const pageId = `pages.workshops.finnoworkshops.${title}`;
  return (
    <Flex sx={{ ...styles.container, ...sx }} ref={ref} className="listItem2" data-cy="workshop-types-offer">
      <Flex sx={styles.content}>
        <Flex sx={styles.title}>
          <SvgIcon name={icon} color="text" sx={id === 0 ? styles.rocket : styles.eye} />
          <SubTitle as="h2">{__(`${pageId}.title`)}</SubTitle>
        </Flex>

        <Paragraph>{__(`${pageId}.paragraph`)}</Paragraph>

        <Text sx={styles.price}>{__(`${pageId}.price`)}</Text>

        <CTAWithOptionalLink
          buttonVariant="cta"
          to={__(`${pageId}.buttonLink`)}
          sx={styles.button}
        >
          {__(`${pageId}.button`)}
        </CTAWithOptionalLink>
      </Flex>
    </Flex>
  );
});

export default WorkshopOffer;
