import React from 'react';
import { Grid, SxProps } from 'theme-ui';
import { AxiosResponse } from 'axios';
import { Paragraph } from '@themed';
import {
  Content,
  findFirstContentWithImage,
  findFirstContentWithTabbedImageList,
  PageMeta,
} from '@types';
import { __, AsyncActionResponse, useAsyncAction } from '@modules';
import { CTAWithOptionalLink, ImageLoader } from '@core';
import { saveSubscription, SubscriptionResponse } from 'src/modules/networking/digital-experience/saveFormData';
import { StudyNewsLetterContent } from '../NewsLetterContent';
import { NewsLetterSignup } from '../NewsLetterSignup';
import { getTextFromContent } from '../chart-generator/components/Guide';
import LoadingPacman from '../chart-generator/LoadingPacman';
import { cmsTextStyles, newsletterStyles, styles } from './PDFForm.styles';
import { useNewsletterId, useSubscribed, useSubscriptionForm } from './usePDFForm';

export interface ContentMapperProps extends SxProps {
  content: Content | undefined;
}

export const ContentMapper = ({ content, ...props }: ContentMapperProps) => (
  <Paragraph {...props} sx={{ ...props.sx }} id="text">
    {getTextFromContent(content)}
  </Paragraph>
);

export interface PageMetaProps {
  content: PageMeta | undefined;
}

// PDFForm helpers
const getDownloadPdf = (content: PageMeta | undefined) => findFirstContentWithImage(content?.content)?.image;
const getTeaserImage = (content: PageMeta | undefined) => findFirstContentWithTabbedImageList(content?.content)?.tabbedImages?.[0]?.image;

const getNewsletterStyles = (resource: AxiosResponse<SubscriptionResponse, any> | null, error: Error | null) => ({
  ...newsletterStyles(true, '#002b3c'),
  ...newsletterStyles(true).newsLetterParagraphStyles(!resource && !error),
});

export const PDFForm = ({ content }: PageMetaProps) => {
  const { value: isSubscribed, setValue: setIsSubscribed } = useSubscribed();
  const { onSubmit, formData } = useSubscriptionForm();
  const asyncResponse = useAsyncAction(() => saveSubscription(formData), [formData]);

  const { resource, error } = asyncResponse;
  const downloadPdf = getDownloadPdf(content);
  const teaserImage = getTeaserImage(content);
  const isDownloadablePDF = Boolean(downloadPdf?.url);

  const newsletterID = useNewsletterId(asyncResponse, isSubscribed, setIsSubscribed, isDownloadablePDF);

  function renderNewsletterContent() {
    if (isDownloadablePDF) {
      return (
        <CTAWithOptionalLink to={downloadPdf?.url}>
          {__('digital-experience.newsletter-subscribed.button')}
        </CTAWithOptionalLink>
      );
    }

    if (!isSubscribed) {
      return <NewsletterContent {...asyncResponse} />;
    }

    return undefined;
  }
  return (
    <Grid id="digital-experience-study" sx={styles()}>
      <ContentMapper sx={cmsTextStyles} content={content?.content?.[0]} />
      <NewsLetterSignup
        id={newsletterID}
        sx={getNewsletterStyles(resource, error)}
        onSubmit={onSubmit}
        Icon={(isSubscribed || isDownloadablePDF) && teaserImage?.url ? (
          <ImageLoader
            src={teaserImage.url}
            alt={__(teaserImage?.alternativeText, true)}
            sx={{ my: 4 }}
          />
        ) : undefined}
      >
        {renderNewsletterContent()}
      </NewsLetterSignup>
    </Grid>
  );
};

interface NewsletterContentProps extends AsyncActionResponse<AxiosResponse<SubscriptionResponse, any> | null> {}

const NewsletterContent = ({ isLoading, resource, error }:NewsletterContentProps) => (
  <React.Fragment>
    {isLoading && <LoadingPacman sx={newsletterStyles().loading} />}
    {!error && !resource && !isLoading && <StudyNewsLetterContent id="digital-experience.newsletter" />}
  </React.Fragment>
);
