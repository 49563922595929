import { SxStyleProp } from 'theme-ui';

export const styles = {
  container: {
    width: '100%',
  },

  rocket: {
    svg: {
      height: '2.5rem',
      width: '2.5rem',
      marginLeft: [0, 0, '1rem'],
      marginRight: ['1rem', '1rem', 0],
    },
    '#rocket_svg__Path1, #rocket_svg__Path3': {
      fill: 'background',
    },
  },

  eye: {
    svg: {
      height: '3rem',
      width: '3rem',
      marginLeft: [0, 0, '1rem'],
      marginRight: ['1rem', '1rem', 0],
    },
    '#eye_svg__Combined-Shape': {
      fill: 'background',
    },
  },

  title: {
    flexDirection: ['row', 'row', 'row-reverse'],
    alignItems: 'center',
  },

  content: {
    flexDirection: 'column',
    alignItems: ['start', 'start', 'center', 'start'],

    h2: {
      color: 'text',
      fontWeight: '600',
      lineHeight: '30px',
      fontSize: ['s', 's', 'm'],
    },

    ul: {
      height: ['100%', '100%', '17rem', '15rem'],
      width: ['100%', '100%', '90%', '80%'],
      variant: 'lists.basicListType',
      li: {
        listStyle: 'outside',
        margin: ['0rem 0rem 1rem 2rem', '0rem 0rem 1rem 2rem', '1rem 0rem 0rem 5rem', '1rem 0rem 0rem 1rem'],
        fontSize: 'xs',
      },
    },
  } as SxStyleProp,

  price: {
    marginBottom: 6,
    marginLeft: 6,
    fontWeight: 'bold',
    fontSize: ['xs', 'xs', 's'],
  },

  button: {
    mt: 3,
    width: ['100%', '100%', '60%'],
    span: {
      bg: 'background',
      color: 'text',

      borderRadius: 999,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
};
