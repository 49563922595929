import React, { useState } from 'react';
import { Box, Button, Flex, Text } from 'theme-ui';
import { __ } from '@modules';
import { Paragraph } from '@themed';
import { CalendlyButton, SectionWithContent, SvgIcon } from '@core';
import { useAnimateWorkshopPackages } from './animateWorkshopPackages';
import { useAnimatePackage } from './animateWorkshopPackageElement';
import { calendlyStyles, styles, packageStyles } from './PackageList.styles';

export const PackageList = () => {
  const finnoWorkshopPackagesRef = React.useRef(null);

  useAnimateWorkshopPackages({
    triggerRef: () => finnoWorkshopPackagesRef,
  });

  return (
    <SectionWithContent
      id="workshops-packages"
      sx={styles.container}
      ref={finnoWorkshopPackagesRef}
    >
      <Flex sx={styles.content}>
        <Flex className="package-container" sx={styles.packageRow}>
          {Array.from({ length: 4 }).map((_, i) => (
            <Package key={i} index={i} />
          ))}
        </Flex>
        <Box sx={calendlyStyles.container}>
          <CalendlyButton
            href={__('pages.aboutus.founders.list.0.calendar')}
            sx={calendlyStyles.button}
            title={__('pages.workshops.packages.button.text')}
          />
          <Text sx={styles.bottomText}>{__('pages.workshops.packages.bottom-text')}</Text>
        </Box>
      </Flex>
    </SectionWithContent>
  );
};

type PackageProps = {
  index: number;
}

function Package({ index }: PackageProps) {
  const packageRef = React.useRef(null);
  const [isUnfolded, setIsUnfolded] = useState(false);
  useAnimatePackage(packageRef, isUnfolded);

  const pageId = `pages.workshops.packages.items.${index}`;

  return (
    <Box className="package" ref={packageRef} sx={packageStyles.container}>

      {/* Front of the card */}
      <Box
        role="button"
        onClick={() => setIsUnfolded(true)}
        className="package-front"
        sx={packageStyles.content('background')}
      >
        <Text sx={packageStyles.title}>{__(`${pageId}.title`)}</Text>
        <Paragraph sx={packageStyles.description}>{__(`${pageId}.description`)}</Paragraph>
        <Paragraph sx={packageStyles.price}>{__(`${pageId}.price`)}</Paragraph>
        <Button
          className="package-button"
          sx={packageStyles.button('primary')}
          onClick={(e) => {
            e.stopPropagation();
            setIsUnfolded(true);
          }}
        >
          <SvgIcon name="add" />
        </Button>
      </Box>

      {/* Back of the card */}
      <Box
        role="button"
        className="package-back"
        sx={packageStyles.content('primary')}
        onClick={() => setIsUnfolded(false)}
      >
        <Text sx={packageStyles.title}>{__(`${pageId}.title`)}</Text>
        <Paragraph sx={packageStyles.description}>{__(`${pageId}.details`)}</Paragraph>
        <Button
          sx={packageStyles.button('background')}
          onClick={(e) => {
            e.stopPropagation();
            setIsUnfolded(false);
          }}
        >
          <SvgIcon name="minus" />
        </Button>
      </Box>
    </Box>
  );
}
