import * as React from "react"

const SvgCircleArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={431}
    height={155}
    viewBox="0 0 431 155"
    fill="none"
    {...props}
  >
    <title>{"circle-arrow"}</title>
    <path
      stroke="#000"
      strokeWidth={2}
      d="M413 77.5c0 10.327-5.37 20.273-15.316 29.431-9.946 9.158-24.395 17.452-42.333 24.438C319.482 145.338 269.86 154 215 154s-104.482-8.662-140.351-22.631c-17.938-6.986-32.386-15.28-42.333-24.438C22.37 97.773 17 87.827 17 77.5c0-10.327 5.37-20.273 15.316-29.43 9.947-9.16 24.395-17.453 42.333-24.439C110.518 9.661 160.14 1 215 1s104.482 8.662 140.351 22.631c17.938 6.986 32.387 15.28 42.333 24.438C407.63 57.227 413 67.173 413 77.5Z"
    />
    <path fill="#1A1919" d="m412.5 84 16.021-14.25h-32.042L412.5 84Z" />
    <path
      fill="#1A1919"
      d="m412.5 84 16.021-14.25h-32.042L412.5 84ZM18.5 65 2.479 79.25h32.043L18.5 65Z"
    />
    <path fill="#1A1919" d="M18.5 65 2.479 79.25h32.043L18.5 65Z" />
  </svg>
)
export default SvgCircleArrow
