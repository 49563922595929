import { graphql, useStaticQuery } from 'gatsby';
import { useLocale } from '@modules';
import { CaseStudy } from '@types';

export function useReferences(type: string) {
  const locale = useLocale();

  const caseStudyNodes = useStaticQuery(graphql`
    query {
      allCaseStudy {
        edges {
          node {
            slug
            title
            site
            subtitle
            teaser
            supportScope
            publishDate
            thumbnails{
              url
              alternativeText
            }
            language
          }
        }
      }
    }
  `);
  const allCaseStudies: CaseStudy[] = caseStudyNodes.allCaseStudy.edges.map((node: { node: CaseStudy }) => node.node);

  const list = allCaseStudies.filter(node => (node.language === locale && node.site === type) && node);
  [list[0], list[1]] = [list[1], list[0]];

  return list;
}
