export const styles = {
  pt: 'headerHeight',
  contact: {
    h2: {
      color: 'text',
    },
    height: ['unset', 'unset', '85vh !important'],
    minHeight: ['unset', 'unset', '85vh !important'],
    '#compact-section-button-link': {
      span: {
        color: 'text',
      },
    },
  },
};
