import { SxStyleProp } from 'theme-ui';
import { compactSectionContentPadding, themeConfig } from '@themed';

export const styles = {
  container: {
    position: 'relative',
    bg: 'background',
    padding: compactSectionContentPadding,
  } as SxStyleProp,

  content: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  } as SxStyleProp,

  referenceContainer: {
    height: '100%',
    flexDirection: ['column', 'column', 'column', 'row'],
    alignItems: 'center',
  } as SxStyleProp,

  reference: {
    position: 'relative',
    width: '100%',
    display: 'grid',
    placeContent: 'stretch',

    '#reference-info': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: ['center', 'center', 'center', 'start'],
      height: ['auto', 'auto', 'auto', '24rem'],
      padding: '0 8px',

      h3: {
        fontSize: 'm',
        textAlign: ['left', 'center', 'center', 'left'],
      },

      p: {
        fontSize: 'xxs',
        padding: 0,
        paddingTop: 5,
        textAlign: ['left', 'center', 'center', 'left'],
      },
    },

    aside: {
      gridRow: 1,
      position: 'relative',
      display: 'grid',
      transform: 'scale(0.6)',
      height: ['20rem', '25vh', '32rem', '30rem'],

      marginTop: '0px !important',
      marginBottom: '0px !important',
      marginLeft: ['0px !important', '0px !important', '0px !important', '20px !important'],

      [`@media screen and (max-width: ${themeConfig.sizes.tabletMaxWidth}) and (orientation: landscape)`]: {
        transform: 'scale(1.4)',
        height: '18rem',
      },
    },
  } as SxStyleProp,

  button: {
    variant: 'buttons.cta',
    color: 'text',
    display: 'flex',
    justifyContent: 'center',
    marginTop: [5, 5, 5, 0],
    width: ['100%', '100%', '100%', '80%'],
  },
};
