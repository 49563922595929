import React from 'react';
import {
  Title, Box, Text, ImageLoader,
  Paragraph,
  Flex,
  SectionWithContent,
} from '@core';
import { __, useAllIntlTree } from '@modules';
import { styles } from './Experts.styles';

export function WorkshopExperts() {
  return (
    <SectionWithContent sx={styles.section}>
      <Flex sx={styles.sectionContent}>
        <Title sx={styles.title}>{__('pages.workshops.experts.title')}</Title>

        <Box sx={styles.content}>
          <Box sx={styles.sectionLeft}>
            <Paragraph sx={styles.description}>
              {__('pages.workshops.experts.description')}
            </Paragraph>
          </Box>

          <Experts />
        </Box>
      </Flex>
    </SectionWithContent>
  );
}

function Experts() {
  const messages = useAllIntlTree();
  const triggerRef = React.useRef(null);

  return (
    <Box sx={styles.sectionRight}>
      <Text sx={styles.sectionRightTitle}>{__('pages.workshops.experts.subTitle')}</Text>
      <Box>
        <Flex ref={triggerRef} sx={styles.experts}>
          {messages.pages.workshops.experts.list.map(({ role, name, image }) => (
            <Box className="element" sx={styles.expertContainer}>
              <Box sx={styles.expertImage}>
                <ImageLoader src={image} alt="" />
              </Box>
              <Box sx={styles.expertText}>
                <Text sx={styles.expertName}>{name}</Text>
                <Text>{role}</Text>
              </Box>
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  );
}
