import React from 'react';
import { SectionWithContent, SxStyleProp } from '@core';
import { FinnoscoreWorkshop } from './FinnoscoreWorkshop';

const sectionStyles = {
  height: ['unset', 'unset', '90vh !important', '100% !important'],
  minHeight: ['unset', 'unset', '90vh !important', '100% !important'],
};

const styles = {
  section: {
    zIndex: 111,
    position: 'relative',
    bg: 'primary',
    paddingTop: [6, 6, 8],
    paddingBottom: [6, 6, 8],

    ...sectionStyles,

    '.content': {
      ...sectionStyles, // To override SectionWithContent variant=styles.section we need to override its .content child too.
    },
  } as SxStyleProp,
};

export function Offers() {
  const finnoWorkshopRef = React.useRef(null);
  return (
    <SectionWithContent
      id="workshops-workshop-types"
      sx={styles.section}
      ref={finnoWorkshopRef}
    >
      <FinnoscoreWorkshop triggerRef={() => finnoWorkshopRef} />
    </SectionWithContent>
  );
}
