import React, { FormEvent } from 'react';
import { AxiosResponse } from 'axios';
import { AsyncActionResponse, SubscriptionFormData, SubscriptionResponse, useCookie } from '@modules';

export const DIGITAL_STUDY_SOURCE = 'digital-insurance-study-2024';

export const useSubscribed = () => {
  // TODO: Utilize the name from the CMS to eliminate the need for code changes when releasing a new study
  const { value, setValue } = useCookie(DIGITAL_STUDY_SOURCE);

  return {
    // TODO: enable cookie based PDF link display in case we would like to launch the report again:
    value: !!value,
    setValue,
  };
};

export function useSubscriptionForm() {
  const [formData, setFormData] = React.useState<SubscriptionFormData | undefined>(undefined);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const [firstName, lastName, email, privacy] = e.target as any;
    setFormData({
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      privacy: privacy.checked,
      source: DIGITAL_STUDY_SOURCE,
    });
  };

  return {
    onSubmit,
    formData,
  };
}

type NewsletterSubscribeStatusId =
  'digital-experience.newsletter' |
  'digital-experience.newsletter-subscribed' |
  'digital-experience.newsletter-subscribed-successfully' |
  'digital-experience.newsletter-subscribed-unsuccessfully';

const BASE_NEWSLETTER_ID = 'digital-experience.newsletter';

export function useNewsletterId(
  { resource, error }: AsyncActionResponse<AxiosResponse<SubscriptionResponse, any> | null>,
  isSubscribed: boolean,
  setIsSubscribed: (s: string) => void,
  isDownloadable: boolean,
) {
  const [newsletterID, setNewsletterID] = React.useState<NewsletterSubscribeStatusId>(BASE_NEWSLETTER_ID);

  const getSubscriptionStatusId = ():NewsletterSubscribeStatusId => (
    isDownloadable
      ? `${BASE_NEWSLETTER_ID}-subscribed`
      : `${BASE_NEWSLETTER_ID}-subscribed-successfully`);

  React.useEffect(() => {
    if (error || (resource && resource.status !== 200)) {
      setNewsletterID(`${BASE_NEWSLETTER_ID}-subscribed-unsuccessfully`);
      return;
    }

    // If there's no resource and already subscribed, mark as successfully subscribed
    if (!resource) {
      if (isSubscribed) {
        setNewsletterID(getSubscriptionStatusId());
      }
      return;
    }

    // Successful form submission
    if (resource.status === 200) {
      setIsSubscribed('true');
      setNewsletterID(getSubscriptionStatusId());
    }
  }, [resource, error, isSubscribed, isDownloadable, setIsSubscribed]);

  return newsletterID;
}
