import { SxStyleProp } from 'theme-ui';

const sectionStyles = {
  height: ['unset', 'unset', '65vh !important', '85vh !important'],
  minHeight: ['unset', 'unset', '65vh !important', '85vh !important'],
};

export const styles = {
  section: {
    bg: 'background',
    ...sectionStyles,

    '.content': {
      ...sectionStyles, // To override SectionWithContent variant=styles.section we need to override its .content child too.
    },
  },

  sectionContent: {
    paddingRight: [4, 7, 4, 0],
    paddingLeft: [4, 7, 4, 0],
    paddingTop: [6, 7, 8],
    paddingBottom: [6, 7, 8],
    height: '100%',
    width: '100%',
    flexDirection: 'column',
  } as SxStyleProp,

  title: {
    color: 'text',
    fontSize: ['l', 'l', 'xl'],
  },

  content: {
    height: '100%',
    display: 'grid',
    placeItems: 'center',
    gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
    gridTemplateRows: ['max-content max-content', 'max-content max-content', '1fr'],
  },

  sectionLeft: {
  } as SxStyleProp,

  description: {
    fontSize: ['xs', 'xs', 's'],
    lineHeight: '32px',
    maxWidth: ['unset', 'unset', '95%'],
  },

  sectionRight: {
    width: '100%',
  },

  sectionRightTitle: {
    fontSize: 's',
    fontWeight: 'bold',
  },

  experts: {
    mt: 5,
    alignItems: 'start',
    justifyContent: 'space-around',
    display: 'flex',
    flexWrap: ['wrap', 'nowrap', 'nowrap'],
  } as SxStyleProp,

  expertContainer: {
    width: ['auto', '100%', '100%'],
  } as SxStyleProp,

  expertImage: {
    borderRadius: 'fullSize',
    width: '9rem',
    height: '9rem',
    overflow: 'hidden',
    margin: 'auto',
  },

  expertText: {
    paddingTop: 4,
    paddingLeft: 4,
    textAlign: ['center', 'center', 'left'],
  } as SxStyleProp,

  expertName: {
    fontWeight: 'bold',
  },
};
