import React from 'react';
import { Flex, SectionWithContent } from '@core';
import { StyledTestimonals } from '../Testimonals';
import { styles, testimonialStyles } from './WorkshopTestimonals.styles';

export function WorkshopTestimonals() {
  const testimonialRef = React.useRef(null);

  return (
    <SectionWithContent id="workshops-testimonials" ref={testimonialRef} variant="styles.layoutMaxWidth" sx={styles.section}>
      <Flex sx={styles.content} variant="flex.colCenter">
        <StyledTestimonals styles={testimonialStyles} />
      </Flex>
    </SectionWithContent>
  );
}
