import React from 'react';
import { Button, Flex, Link, SectionWithContent } from '@core';
import { __, useLocale } from '@modules';
import { Reference } from '../rapid/Reference';
import { styles } from './References.styles';
import { useReferences } from './useReferences';

export function References() {
  const locale = useLocale();
  const references = useReferences('finno');

  const triggerRef = React.useRef(null);

  return (
    <SectionWithContent
      id="workshops-references"
      sx={styles.container}
      ref={triggerRef}
    >
      <Flex sx={styles.content}>
        <Flex className="package-container" sx={styles.referenceContainer}>
          {references.map((reference) => (
            <Reference
              className="package"
              key={reference.slug}
              {...reference}
              sx={styles.reference}
            >
              <Link sx={{ width: '100%' }} to={`/${locale}/case-study/${reference.slug}`}>
                <Button sx={styles.button}>{__('pages.casestudies.cases.button')}</Button>
              </Link>
            </Reference>
          ))}
        </Flex>
      </Flex>
    </SectionWithContent>
  );
}
