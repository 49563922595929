import * as React from "react"

const SvgAdd = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={2.5}
      d="M1.75 11.75h21.5M12.75 1.25v21.5"
    />
  </svg>
)
export default SvgAdd
