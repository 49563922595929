import axios, { AxiosResponse } from 'axios';
import { getStrapiRoutes } from '../routes';

export interface SubscriptionResponse {
  status: number;
  message: string;
}

export interface SubscriptionFormData {
  firstName: string;
  lastName: string;
  email: string;
  privacy: string;
  source: string;
  answer?: string;
  address?: string;
  additionalInfo?: Record<string, unknown>;
}

export const saveSubscription = async (data?: SubscriptionFormData) => {
  if (data) {
    const response: AxiosResponse<SubscriptionResponse> = await axios.post(getStrapiRoutes().cms.saveSubscription, data, { headers: { 'Content-Type': 'application/json' } });
    return response;
  }
  return null;
};
