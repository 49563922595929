/** @jsx jsx */
import React from 'react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import { jsx, CTAButton, SxProps } from '@themed';
import { ChildrenProps } from '@types';
import { formatSEOLink, isExternalLink } from '@modules';

export interface ExternalLinkProps<TState> extends GatsbyLinkProps<TState>{}

interface LinkProps {}

function ExternalLink({
  children, to, ...props
}: ExternalLinkProps<LinkProps>) { // TODO: more proper TS typing
  return <a href={to} {...props} sx={{ variant: 'links.external' }}>{children}</a>;
}

// TODO: more proper TS typing (with any)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GatsbyLinkWithSlash = React.forwardRef<HTMLAnchorElement, ExternalLinkProps<any>>(({
  to, ...props
}, ref) => <GatsbyLink {...props} to={formatSEOLink(to)} ref={ref as unknown as string} />); // TODO: more proper TS typing, as it seems Gatsby Typing uses ref:string, however that's not likely

export const Link = React.forwardRef<HTMLAnchorElement, ExternalLinkProps<any>>((props, ref) => (isExternalLink(props.to) ? <ExternalLink {...props} /> : <GatsbyLinkWithSlash {...props} ref={ref} />));

export interface OptionalLinkProps extends ChildrenProps, SxProps, Partial<Pick<HTMLAnchorElement, 'target' | 'className'>>{
  id?: string;
  to?: string;
}

export const OptionalLink = ({
  to, children, ...props
}: OptionalLinkProps & {onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void}) => {
  if (!to) return <React.Fragment>{children}</React.Fragment>;

  return (
    <Link to={to} {...props} sx={{ width: 'max-content', ...props.sx }}>
      {children}
    </Link>
  );
};

interface CTAWithOptionalLink extends OptionalLinkProps{
  buttonVariant?: string;
}

export const CTAWithOptionalLink = ({
  to, children, buttonVariant, ...props
}: CTAWithOptionalLink) => (
  <OptionalLink {...props} to={to}>
    <CTAButton
      as={to ? 'span' : undefined}
      sx={{ variant: `buttons.${buttonVariant || 'cta'}` }}
    >
      {children}
    </CTAButton>
  </OptionalLink>
);
