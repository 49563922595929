import { SxStyles } from '@types';

export const finnoscoreCellPadding = [4, 4, 0];

export const finnoscoreDataStyles = {
  main: {
    backgroundColor: 'muted',
  },
  section: {
    bg: ['background', 'background', 'transparent'],
    minHeight: 'calc(100vh - 36px) !important',
  },
  page: {
    variant: 'styles.section',
    width: 'fullSize',
    maxWidth: ['tabletMaxWidth', 'tabletMaxWidth', 'tabletMaxWidth', 'desktopMaxWidth'],
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: ['column', 'column', 'column', 'row'],
    mt: ['headerHeight', 'headerHeight', '80px', 'headerHeight'],
  },
  control: {
    width: 'fullSize',
    px: [4, 4, 0],
    position: 'relative',
    display: 'flex',
    // height: ['min-content', 'fsHeaderHeightMobile', 'fsHeaderHeightDesktop'],
    flexDirection: ['column', 'column', 'row'],
    justifyContent: ['center', 'flex-end'],
    alignItems: 'center',
  } as SxStyles,
  content: {
    gridTemplateColumns: [
      '1fr',
      '350px 1fr',
      'auto 1fr',
      '5fr 13fr',
    ],
    gridTemplateRows: [
      'repeat(3, auto) 60vh 100px auto',
      'repeat(3, auto) 70vh auto',
      'repeat(3, auto) 70vh max-content',
      '80px max-content 96px 1fr',
    ],
    gridTemplateAreas: [
      '"report" "chartType" "control" "content" "contact" "press"',
      '"report report" "chartType chartType" "control control" "content content" "press press"',
      '"report report" "contact contact" "chartType control" "content content" "press press"',
      '"chartType control" "report content" "contact content" "press content"',
    ],
    rowGap: ['12px', 4],
    columnGap: [0, 0, 4, 6],
    '&>#chartType': {
      gridArea: 'chartType',
    },
    '&>#report': {
      gridArea: 'report',
      px: finnoscoreCellPadding,
    },
    '&>#contact': {
      alignSelf: ['flex-end', 'flex-start'],
      gridArea: 'contact',
      px: finnoscoreCellPadding,
    },
    '&>#press': {
      gridArea: 'press',
      px: finnoscoreCellPadding,
      mb: [6, 6, 6, 0],
    },
    '&>#control': {
      gridArea: 'control',
    },
    '&>#loader': {
      gridArea: 'content',
      mx: 'auto',
    },
    '&>#content': {
      gridArea: 'content',
      width: '100%',
      height: ['60vh', '70vh'],

      justifyContent: 'flex-start',
      '&>*': {
        width: '100%',
        maxHeight: '100%',
      },
    },
    width: 'fullSize',
  },
  reportButton: {
    span: {
      margin: 'auto',
      marginRight: '1rem',
    },
  },
  tabSelector: {
    marginBottom: 'auto',
    marginTop: 'auto',
    width: ['auto', 'max-content'],
    mx: [4, 4, 0],
    boxShadow: ['none', 'none', '0 0 6px 0 rgba(0, 0, 0, 0.1)'],
  },
  error: {
    mt: 4,
  },
};
