import * as React from "react"

const SvgMinus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={3}
    viewBox="0 0 25 3"
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={2.5}
      d="M1.75 1.75h21.5"
    />
  </svg>
)
export default SvgMinus
